/*=======================================================
	
                   G o o d L u c k

=========================================================*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.container-login {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /*grid-gap: 7rem;*/
  /*padding: 0 2rem;*/
  grid-gap: 0rem;
  padding: 0 0rem;
}
.container-login::-webkit-scrollbar {
  display: none;
}
.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.img img {
  width: 850px;
}
.login-container {
  display: flex;
  align-items: center;
  text-align: center;
}

.avator {
  /* width: 100px; */
}

form {
  width: 360px;
  font-family: "Poppins", sans-serif;
}

form h2 {
  font-size: 1.9rem;
  /* text-transform: uppercase; */
  /* letter-spacing: 5px; */
  margin: 15px 0;
  color: #333;
  font-family: "Poppins", sans-serif;
  /*color: #A9A9A9;*/
}

.input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}
.input-div:after,
.input-div::before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #5557ec;
  transition: 0.3s;
}

.input-div:after {
  right: 50%;
}
.input-div::before {
  left: 50%;
}
.input-div.focus .i i {
  color: #5557ec;
}
.input-div.focus div h5 {
  top: -5px;
  font-size: 15px;
}
.input-div.focus:after,
.input-div.focus:before {
  width: 50%;
}
.input-div.one {
  margin-top: 0;
}
.input-div.two {
  margin-top: 4px;
}

.i {
  display: flex;
  justify-content: center;
  align-items: center;
}
.i i {
  color: #d9d9d9;
  transition: 0.3s;
}

.input-div > div {
  position: relative;
  height: 45px;
}

.input-div > div h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  color: #555;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

a {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: 0.3s;
}
a:hover {
  color: #5557ec;
}
.btn-login {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  margin: 1rem 0;
  font-size: 1.2rem;
  outline: none;
  border: none;
  /* background-image: linear-gradient(to right, #5557ec, #3134db, #1e2097); */
  cursor: pointer;
  color: #fff;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  background-size: 200%;
  text-transform: uppercase;
  background: rgb(246, 145, 16);
  background: linear-gradient(257deg, rgba(246, 145, 16, 1) 34%, rgba(246, 209, 16, 1) 100%);
  transition: 0.5s;
}

.btn-login:hover {
  background-position: right;
}

footer {
  width: 100%;
  background-color: #32323267;
  color: #f2f2f2;
  height: 8vh;
  text-align: center;
  padding-top: 3vh;
  padding-bottom: 1vh;
}
/* important part for any devices */
@media screen and (max-width: 1050px) {
  .container {
    grid-gap: 5rem;
  }
  body {
    background-color: rgb(86, 153, 216);
  }
  form h2 {
    color: whitesmoke;
  }
  .input-div > div h5 {
    color: snow;
  }
  a {
    color: snow;
  }
}

@media screen and (max-width: 1000px) {
  form {
    width: 290px;
  }
  form h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }
  .img img {
    width: 400px;
  }
  body {
    /* background-color: blueviolet; */
  }
  form h2 {
    color: whitesmoke;
  }
  .input-div > div h5 {
    color: snow;
  }
  a {
    color: snow;
  }
}
@media screen and (max-width: 900px) {
  .img {
    display: none;
  }
  .container {
    grid-template-columns: 1fr;
  }

  .login-container {
    justify-content: center;
  }
  footer {
    width: auto;
    height: auto;
  }
  body {
    /* background-color: black; */
  }
  form h2 {
    color: whitesmoke;
  }
  .input-div > div h5 {
    color: snow;
  }
  a {
    color: snow;
  }
}

/*=========== T H A N K   Y O U =============*/
