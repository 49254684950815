@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://use.fontawesome.com/releases/v5.8.2/css/all.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/css/mdb.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js");
@import url("https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js");
@import url("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/js/bootstrap.min.js");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.mainWrapper {
  width: 100%;
  height: 100vh;
  font-family: "poppins", sans-serif;
  overflow: hidden;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.mainWrapper .leftContainer {
  width: 18%;
  height: 100vh;
  -webkit-box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
          box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  overflow-y: scroll;
  position: relative;
}

.mainWrapper .leftContainer .logo {
  width: 100%;
  height: 30%;
  z-index: +1;
}

.mainWrapper .leftContainer .navList {
  width: 100%;
  height: 70%;
  z-index: +1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.mainWrapper .leftContainer .navList div {
  height: 11%;
  position: relative;
}

.mainWrapper .leftContainer .navList div a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-decoration: none;
  color: #f4f8f8;
}

.mainWrapper .leftContainer .navList div a .icon {
  width: 15%;
  margin-left: 8%;
}

.mainWrapper .leftContainer .navList div a span {
  width: 85%;
}

.mainWrapper .leftContainer .navList div a.active:after {
  content: "";
  display: block;
  border: 12px solid rgba(255, 253, 253, 0);
  border-right-color: #f4f8f8;
  position: absolute;
  right: 0;
  margin-left: -12px;
}

.mainWrapper .leftContainer .navList div a:hover:after {
  content: "";
  display: block;
  border: 12px solid rgba(255, 253, 253, 0);
  border-right-color: #f4f8f8;
  position: absolute;
  right: 0;
  margin-left: -12px;
}

.mainWrapper .leftContainer .bg {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: -40px;
}

.mainWrapper .leftContainer .bg img {
  height: 100vh;
}

.mainWrapper .rightContainer.small {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mainWrapper .rightContainer.small .topBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 10px;
}

.mainWrapper .rightContainer.small .topBar h3 {
  font-weight: 500;
}

.mainWrapper .rightContainer.small .topBar .toolSpace {
  width: 70px;
  margin-right: 6px;
}

.mainWrapper .rightContainer.small .topBar .toolSpace.dash {
  position: relative;
  top: 15px;
}

.mainWrapper .rightContainer.small .contentContainer {
  height: 90%;
  margin: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mainWrapper .rightContainer.small .contentContainer .cards {
  width: 100%;
  height: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardA {
  cursor: pointer;
  width: 25%;
  min-width: 250px;
  height: 80%;
  max-height: 120px;
  -webkit-box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
          box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  border-radius: 10px;
  color: white;
  margin: 5px;
  padding: 5px;
  background: #ed4787;
  background: -webkit-gradient(linear, right top, left bottom, from(#ed4787), to(#b756a6));
  background: linear-gradient(to bottom left, #ed4787, #b756a6);
  max-width: 350px;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardA .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardA .left .title {
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardA .left .svg {
  color: white;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardA .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardA .right .count {
  font-size: 3rem;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardA .right .date {
  font-size: 0.9rem;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardB {
  cursor: pointer;
  max-width: 350px;
  width: 25%;
  min-width: 250px;
  height: 80%;
  max-height: 120px;
  -webkit-box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
          box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  border-radius: 10px;
  color: white;
  margin: 5px;
  padding: 5px;
  background: #7d5ac0;
  overflow: hidden;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardB .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardB .left .title {
  font-size: 0.8rem;
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardB .left .count {
  font-size: 3rem;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardB svg {
  position: absolute;
  bottom: 0;
  top: 60px;
  right: 0;
  left: 0;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardC {
  width: 25%;
  height: 80%;
  max-width: 350px;
  min-width: 250px;
  cursor: pointer;
  max-height: 121px;
  -webkit-box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
          box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  border-radius: 10px;
  color: white;
  margin: 5px;
  padding: 5px;
  background: #44c4f3;
  background: -webkit-gradient(linear, left top, right bottom, from(#44c4f3), to(#6491db));
  background: linear-gradient(to bottom right, #44c4f3, #6491db);
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardC .left {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardC .left .title {
  font-size: 0.8rem;
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardC .left .count {
  font-size: 3rem;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardC .left .badge {
  letter-spacing: 2px;
  font-size: 0.8rem;
  padding: 2px;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardC .right {
  width: 50%;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardC .right .svg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardD {
  cursor: pointer;
  width: 25%;
  min-width: 250px;
  height: 80%;
  max-height: 120px;
  -webkit-box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
          box-shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  border-radius: 10px;
  color: white;
  margin: 5px;
  padding: 5px;
  background: #ffb92d;
  background: -webkit-gradient(linear, left top, right bottom, from(#ffb92d), to(#f8805b));
  background: linear-gradient(to bottom right, #ffb92d, #f8805b);
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardD .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardD .left .title {
  text-transform: uppercase;
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardD .left .svg {
  color: white;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardD .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardD .right .count {
  font-size: 3rem;
}

.mainWrapper .rightContainer.small .contentContainer .cards .cardD .right .date {
  font-size: 0.9rem;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 {
  width: 50%;
  height: 88px;
  overflow: hidden;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-radius: 15px;
  padding-bottom: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: #e0e0e0;
  /* Hide scrollbar for IE, Edge and Firefox */
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .linkTo {
  width: 100%;
  padding: 15px 5px 15px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .linkTo .titleTag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .linkTo .titleTag h4 {
  color: #e0e0e0;
  padding: 10px 1px;
  margin-bottom: 0;
  height: 50px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .linkTo .titleTag .arrow {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .linkTo a {
  color: black;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .linkTo a:hover {
  padding: 5px;
  border-radius: 50%;
  color: #e0e0e0;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .linkTo span {
  font-size: 1rem;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .tableA {
  height: 400px;
  padding: 12px;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
  border-radius: 20px;
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: #000000;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin: 10px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .tableA table thead tr:first-child {
  top: -12px;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  background: #ffffff;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .tableA table thead tr:first-child th {
  font-weight: 400;
  font-size: medium;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .tableA table tbody tr td {
  font-weight: 500;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .tableA table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .tableA table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .tableA::-webkit-scrollbar {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1 .tableA {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.liveClasses .linkTo .titleTag h4 {
  background-color: #2573ef;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.liveClasses .linkTo .titleTag .arrow {
  border-left: 25px solid #2573ef;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.liveClasses .linkTo a:hover {
  background-color: #2573ef;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.liveClasses .tableA {
  border-bottom-color: #2573ef;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.liveClasses .tableA table thead tr:first-child th {
  color: #2573ef;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.installments .linkTo .titleTag h4 {
  background-color: #cc2037;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.installments .linkTo .titleTag .arrow {
  border-left: 25px solid #cc2037;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.installments .linkTo a:hover {
  background-color: #cc2037;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.installments .tableA {
  border-bottom-color: #cc2037;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.installments .tableA table thead tr:first-child th {
  color: #cc2037;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.liveClasses.show {
  height: 500px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1.installments.show {
  height: 500px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom .dashBotCard1:hover {
  cursor: pointer;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 {
  width: 50%;
  height: 88px;
  overflow: hidden;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-radius: 15px;
  padding-bottom: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: #e0e0e0;
  /* Hide scrollbar for IE, Edge and Firefox */
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .linkTo {
  width: 100%;
  padding: 15px 5px 15px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .linkTo .titleTag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .linkTo .titleTag h4 {
  color: #e0e0e0;
  padding: 10px 1px;
  margin-bottom: 0;
  height: 50px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .linkTo .titleTag .arrow {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .linkTo a {
  color: black;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .linkTo a:hover {
  padding: 5px;
  border-radius: 50%;
  color: #e0e0e0;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .linkTo span {
  font-size: 1rem;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .tableA {
  height: 400px;
  padding: 12px;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
  border-radius: 20px;
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: #000000;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin: 10px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .tableA table thead tr:first-child {
  top: -12px;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  background: #ffffff;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .tableA table thead tr:first-child th {
  font-weight: 400;
  font-size: medium;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .tableA table tbody tr td {
  font-weight: 500;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .tableA table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .tableA table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .tableA::-webkit-scrollbar {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1 .tableA {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.scores .linkTo .titleTag h4 {
  background-color: #15bb5a;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.scores .linkTo .titleTag .arrow {
  border-left: 25px solid #15bb5a;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.scores .linkTo a:hover {
  background-color: #15bb5a;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.scores .tableA {
  border-bottom-color: #15bb5a;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.scores .tableA table thead tr:first-child th {
  color: #15bb5a;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.enquiry .linkTo .titleTag h4 {
  background-color: #6411ad;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.enquiry .linkTo .titleTag .arrow {
  border-left: 25px solid #6411ad;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.enquiry .linkTo a:hover {
  background-color: #6411ad;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.enquiry .tableA {
  border-bottom-color: #6411ad;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.enquiry .tableA table thead tr:first-child th {
  color: #6411ad;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.scores.show {
  height: 500px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1.enquiry.show {
  height: 500px;
}

.mainWrapper .rightContainer.small .contentContainer .dashBottom2 .dashBotCard1:hover {
  cursor: pointer;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
}

.mainWrapper .rightContainer.small .contentContainer .contentTop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
}

.mainWrapper .rightContainer.small .contentContainer .table.show {
  width: 100%;
  height: 100%;
  padding: 1%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mainWrapper .rightContainer.small .contentContainer .table.show .dataSearch {
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .table.show .dataSearch input,
.mainWrapper .rightContainer.small .contentContainer .table.show .dataSearch a .exportBtn {
  width: 15% !important;
  position: absolute !important;
  right: 0 !important;
}

.mainWrapper .rightContainer.small .contentContainer .table.show .container {
  overflow-y: visible;
}

.mainWrapper .rightContainer.small .contentContainer .table.show .container table thead[data-test="table-foot"] {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .table.show .container::-webkit-scrollbar {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .table.hide {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .form.show form {
  margin: auto;
}

.mainWrapper .rightContainer.small .contentContainer .form.show form button {
  margin: 2px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show form .instr {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mainWrapper .rightContainer.small .contentContainer .form.show form .instr .instr_select {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mainWrapper .rightContainer.small .contentContainer .form.show form .instr .instr_select .add {
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  border-radius: 10px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  margin-top: 5px;
  padding: 0px 5px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show form .instr .instr_select .add select {
  width: 30%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show form .instr .instr_table {
  width: 100%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .formWrapper.user {
  width: 40%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user {
  width: 60%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm {
  width: 40%;
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: 10px;
  border-radius: 20px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard h2 {
  font-size: 16px;
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard svg {
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer {
  display: none;
  width: 100%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer.show .timingSlots {
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #4285f4;
  color: #ffffff;
  margin: 5px 2px;
  font-size: 12px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer.show form {
  display: inline-block;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer.show form .inputContainer {
  display: inline-block;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer.show form .inputContainer label {
  font-size: 12px;
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer.show form .inputContainer input {
  font-size: 12px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer.show form button {
  border: none;
  background: none;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorForm .inputCard .timingContainer.show form button svg {
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.hide {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat {
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row:hover {
  background-color: gainsboro;
  border-radius: 30px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .title {
  width: 18%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .title p {
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slick-slider {
  width: 70% !important;
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slick-slider .slick-list .timingSlots {
  border: none;
  outline: none;
  min-width: 100px;
  padding: 5px;
  border-radius: 20px;
  background-color: #4285f4;
  color: #ffffff;
  margin: 5px 10px;
  font-size: 11px;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slick-slider .slick-list .timingSlots span {
  background-color: #ffb604;
  padding: 5px 10px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 0;
  bottom: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slick-slider .slick-list .timingSlots.selected {
  border: none;
  outline: none;
  min-width: 100px;
  padding: 5px;
  border-radius: 20px;
  background-color: #121212;
  color: #ffffff;
  margin: 5px 10px;
  font-size: 11px;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slick-slider .slick-list .timingSlots.selected span {
  background-color: #ffb604;
  padding: 5px 10px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 0;
  bottom: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 70%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots svg {
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots .timings {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  overflow-x: scroll;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots .timings .timingSlots {
  border: none;
  outline: none;
  min-width: 100px;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #4285f4;
  color: #ffffff;
  margin: 5px 10px;
  font-size: 12px;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots .timings .timingSlots span {
  background-color: #ffb604;
  padding: 5px 10px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 0;
  bottom: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots .timings .timingSlots.selected {
  border: none;
  outline: none;
  min-width: 100px;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #121212;
  color: #ffffff;
  margin: 5px 10px;
  font-size: 12px;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots .timings .timingSlots.selected span {
  background-color: #ffb604;
  padding: 5px 10px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 0;
  bottom: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots .timings::-webkit-scrollbar {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .formInputs .cardPreview.user .instructorTable .instructorView.show .tableFormat .row .slots::-webkit-scrollbar {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected {
  width: 65%;
  height: 100%;
  margin-bottom: 10px;
  font-family: "poppins", sans-serif;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected .selectedTimeCard {
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  height: 60px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  margin-bottom: 20px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected .selectedTimeCard .cardNumber {
  width: 10%;
  margin-left: 10px;
  position: relative;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected .selectedTimeCard .cardNumber .numberCircle {
  border: 1px solid black;
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding-top: 8px;
  font-size: 1.1rem;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected .selectedTimeCard .slotDetails {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected .selectedTimeCard .slotDetails .day {
  font-size: 1.2rem;
  font-weight: 500;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected .selectedTimeCard .slotDetails .time {
  font-weight: 400;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected .selectedTimeCard .slotNote {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.selected .selectedTimeCard .slotNote .noteBadge {
  margin-right: 15px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor {
  width: 100%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm {
  width: 40%;
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: 10px;
  border-radius: 20px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard h2 {
  font-size: 16px;
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard svg {
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer {
  display: none;
  width: 100%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show .timingSlots {
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #4285f4;
  color: #ffffff;
  font-size: 12px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show .timingSlots .hoverDelete {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show .timingSlots:hover .hoverDelete {
  display: inline-block;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show form {
  display: inline-block;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show form .inputContainer {
  display: inline-block;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show form .inputContainer label {
  font-size: 12px;
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show form .inputContainer input {
  font-size: 12px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show form button {
  border: none;
  background: none;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorForm .inputCard .timingContainer.show form button svg {
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView {
  width: 60%;
  margin: 0;
  padding: 0;
  height: 100%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: 24.5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .title {
  width: 18%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .title p {
  margin: 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .slick-slider {
  width: 65% !important;
  margin: 10px 0;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .slick-slider .slick-list span.timingSlots {
  min-width: 180px;
  padding: 0 5px;
  border-radius: 20px;
  background-color: #4285f4;
  color: #ffffff;
  margin: 0px 2px;
  font-size: 11px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .slots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 68%;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .slots svg {
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .slots .timings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow-x: scroll;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .slots .timings .timingSlots {
  min-width: 140px;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #4285f4;
  color: #ffffff;
  margin: 5px 2px;
  font-size: 12px;
}

.mainWrapper .rightContainer.small .contentContainer .form.show .formTwoWrapper .cardPreview.instructor .instructorTable .instructorView .tableFormat .row .slots .timings::-webkit-scrollbar {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .form.hide {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer .purchaseDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mainWrapper .rightContainer.small .contentContainer .purchaseDetails .sectionOne {
  width: 23%;
  padding: 10px;
  border-radius: 10px;
}

.mainWrapper .rightContainer.small .contentContainer .purchaseDetails .sectionOne .sectionOneHeader h4 {
  color: #504f4f;
}

.mainWrapper .rightContainer.small .contentContainer .purchaseDetails .sectionOne .sectionOneContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .purchaseDetails .sectionTwo {
  width: 75%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mainWrapper .rightContainer.small .contentContainer .purchaseDetails .sectionTwo .sectionTwoHeader h4 {
  color: whitesmoke;
}

.mainWrapper .rightContainer.small .contentContainer .purchaseDetails .sectionTwo .sectionTwoContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent {
  width: 100%;
  margin: auto;
  max-width: 945px;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 2%;
  border-radius: 20px;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft {
  width: 48%;
  height: 100%;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile {
  height: 100%;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profilePic {
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profilePic img {
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0px 0px;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profileDetails {
  width: 100%;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 1%;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profileDetails .detailItem {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 3%;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profileDetails .detailItem h4 {
  margin-bottom: 0 !important;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profileDetails .detailItem span {
  font-size: 1rem;
  border-bottom: 1px solid #270b8273;
  padding-bottom: 5px;
  width: 48%;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profileDetails .detailItem span.mail {
  width: 100%;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profileDetails .detailItem span.smsalert {
  border: none;
  color: #f05e3c;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionLeft .cardProfile .profileDetails .detailItem span.active {
  border: none;
  width: 20px;
  height: 20px;
  background: -webkit-gradient(linear, left top, right top, from(#f8703c), to(#fb3780));
  background: linear-gradient(90deg, #f8703c 0%, #fb3780 100%);
  border-radius: 50%;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight {
  width: 48%;
  padding: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards {
  height: 48%;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardHeader {
  min-height: 65px;
  border-bottom: 1px solid whitesmoke;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardHeader .cardTitle {
  margin-left: 10px;
  font-size: large;
  font-weight: 500;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardHeader .toolSpace .searchIcon {
  margin: 5%;
  cursor: pointer;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardHeader .toolSpace .editButton {
  border-radius: 26px;
  padding: 4% 10%;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody .accountsItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 15px;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody .accountsItem .paidStatus {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(#b4c73c), to(#099900));
  background: linear-gradient(90deg, #b4c73c 0%, #099900 100%);
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody .accountsItem .notPaidStatus {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(#f8703c), to(#fb3780));
  background: linear-gradient(90deg, #f8703c 0%, #fb3780 100%);
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody .accountsItem .details .statusType {
  font-size: 16px;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody .accountsItem .details .accountId {
  opacity: 0.9;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody .accountsItem span {
  color: white;
  border-radius: 25px;
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody .accountsItem .bAccount {
  background: -webkit-gradient(linear, left top, right top, from(#f8703c), to(#fb3780));
  background: linear-gradient(90deg, #f8703c 0%, #fb3780 100%);
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody .accountsItem .ubAccount {
  background: -webkit-gradient(linear, left top, right top, from(#b4c73c), to(#099900));
  background: linear-gradient(90deg, #b4c73c 0%, #099900 100%);
}

.mainWrapper .rightContainer.small .contentContainer .profileContent .sectionRight .cards .cardBody::-webkit-scrollbar {
  display: none;
}

.mainWrapper .rightContainer.small .contentContainer.dash {
  position: relative;
  bottom: 90px;
}

.mainWrapper .rightContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mainWrapper .rightContainer::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.mainWrapper .rightContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #270b82;
}

#datepicker {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}

.classCard {
  width: 100%;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  margin: 10px;
}

.classCard .number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 24px;
  border-radius: 50%;
  text-align: center;
}

.classCard .info {
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.classCard .info .date {
  margin: 0;
  font-weight: 500;
}

.classCard .info .time {
  margin: 0;
}

.classCard .day {
  margin: 0;
  margin-right: 30px;
  white-space: nowrap;
}

.available {
  font-size: 18px;
  background-color: green;
  border-radius: 20px;
  color: #ffffff;
  text-align: center;
  width: 50%;
  margin: auto;
}

.notAvailable {
  font-size: 18px;
  background-color: red;
  border-radius: 20px;
  color: #ffffff;
  text-align: center;
  width: 50%;
  margin: auto;
}

.toolSpace {
  width: 25%;
  /* .searchBar {
        color: white;
        cursor: pointer;
    } */
}

.toolSpace section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.toolSpace section .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80%;
}

.toolSpace section .profile img {
  border-radius: 50%;
  margin: 0px 10px;
}

.toolSpace section .profile p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.toolSpace section svg {
  cursor: pointer;
}

.toolSpace .toggleButton {
  color: white;
  cursor: pointer;
  display: none;
}

.toolSpace .sb .tb {
  display: table;
  width: 100%;
}

.toolSpace .sb .td {
  display: table-cell;
  vertical-align: middle;
}

.toolSpace .sb input {
  color: #fff;
  font-family: Nunito;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
}

.toolSpace .sb #cover {
  padding: 35px;
  margin: 0 auto;
  margin-top: 40px;
  background-color: #ff7575;
  border-radius: 20px;
  -webkit-box-shadow: 0 10px 40px #ff7c7c, 0 0 0 20px #ffffffeb;
          box-shadow: 0 10px 40px #ff7c7c, 0 0 0 20px #ffffffeb;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}

.toolSpace .sb input[type="text"] {
  width: 100%;
  height: 96px;
  font-size: 60px;
  line-height: 1;
}

.toolSpace .sb input[type="text"]::-webkit-input-placeholder {
  color: #e16868;
}

.toolSpace .sb input[type="text"]:-ms-input-placeholder {
  color: #e16868;
}

.toolSpace .sb input[type="text"]::-ms-input-placeholder {
  color: #e16868;
}

.toolSpace .sb input[type="text"]::placeholder {
  color: #e16868;
}

.toolSpace .sb #s_cover {
  width: 1px;
  padding-left: 35px;
}

.toolSpace .sb #s_cover:hover #s-circle {
  top: -1px;
  width: 67px;
  height: 15px;
  border-width: 0;
  background-color: #fff;
  border-radius: 20px;
}

.toolSpace .sb #s_cover:hover span {
  top: 50%;
  left: 56px;
  width: 25px;
  margin-top: -9px;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
}

.toolSpace .sb #s_cover:hover button span:before {
  bottom: 11px;
  -webkit-transform: rotateZ(52deg);
  transform: rotateZ(52deg);
  right: -6px;
  width: 40px;
  background-color: #fff;
}

.toolSpace .sb #s_cover:hover button span:after {
  bottom: -11px;
  -webkit-transform: rotateZ(-52deg);
  transform: rotateZ(-52deg);
  right: -6px;
  width: 40px;
  background-color: #fff;
}

.toolSpace .sb button {
  position: relative;
  display: block;
  width: 84px;
  height: 96px;
  cursor: pointer;
}

.toolSpace .sb button span {
  position: absolute;
  top: 68px;
  left: 43px;
  display: block;
  width: 45px;
  height: 15px;
  background-color: transparent;
  border-radius: 10px;
  -webkit-transform: rotateZ(52deg);
  transform: rotateZ(52deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.toolSpace .sb button span:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45px;
  height: 15px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.toolSpace .sb button span:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45px;
  height: 15px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.toolSpace .sb #s-circle {
  position: relative;
  top: -8px;
  left: 0;
  width: 43px;
  height: 43px;
  margin-top: 0;
  border-width: 15px;
  border: 15px solid #fff;
  background-color: transparent;
  border-radius: 50%;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.toolSpace button {
  color: #fff;
  font-family: Nunito;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
}

.toolSpace .sb-example-3 .search__title {
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  color: #ff8b88;
}

.toolSpace .sb-example-3 .search__input {
  width: 100%;
  padding: 12px 24px;
  background-color: transparent;
  -webkit-transition: -webkit-transform 250ms ease-in-out;
  transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
  font-size: 14px;
  line-height: 18px;
  color: #575756;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
  border-radius: 50px;
  border: 1px solid #575756;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.toolSpace .sb-example-3 .search__input::-webkit-input-placeholder {
  color: rgba(87, 87, 86, 0.8);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.toolSpace .sb-example-3 .search__input:-ms-input-placeholder {
  color: rgba(87, 87, 86, 0.8);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.toolSpace .sb-example-3 .search__input::-ms-input-placeholder {
  color: rgba(87, 87, 86, 0.8);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.toolSpace .sb-example-3 .search__input::placeholder {
  color: rgba(87, 87, 86, 0.8);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.toolSpace .sb-example-3 .search__input:hover {
  padding: 12px 0;
  outline: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #575756;
  border-radius: 0;
  background-position: 100% center;
}

.toolSpace .search__input:focus {
  padding: 12px 0;
  outline: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #575756;
  border-radius: 0;
  background-position: 100% center;
}

.search {
  width: 60px;
  height: 60px;
  -webkit-transform: 0.5s;
          transform: 0.5s;
  background: #fff;
  overflow: hidden;
  position: relative;
  border-radius: 60px;
  -webkit-box-shadow: 0 0 0 0 5px #2573ef;
          box-shadow: 0 0 0 0 5px #2573ef;
}

.search.active {
  width: 360px;
}

.search .icon {
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1000;
  cursor: pointer;
  position: absolute;
  background: #fff;
  border-radius: 60px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.search .icon:before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #287dfc;
  -webkit-transform: translate(-4px, -4px);
          transform: translate(-4px, -4px);
}

.search .icon:after {
  content: "";
  width: 3px;
  height: 12px;
  position: absolute;
  background: #287dfc;
  -webkit-transform: translate(6px, 6px) rotate(315deg);
          transform: translate(6px, 6px) rotate(315deg);
}

.search .input {
  left: 60px;
  width: 300px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.search .input input {
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  padding: 10px 0;
  position: absolute;
}

.clear {
  top: 50%;
  right: 15px;
  width: 15px;
  height: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  background: #ff0;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.clear:before {
  width: 1px;
  height: 15px;
  content: "";
  position: absolute;
  background: #999;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.clear:after {
  width: 1px;
  height: 15px;
  content: "";
  position: absolute;
  background: #999;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}

.form-check-input:checked {
  background-color: #308d05 !important;
  border-color: #308d05 !important;
}

.slick-prev:before,
.slick-next:before {
  color: #121212 !important;
}

.purchaseDetails {
  margin-bottom: 30px;
}

.purchaseDetails .form {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.purchaseDetails .form .input {
  position: relative;
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}

.purchaseDetails .form .input button.purchaseBtn {
  border: none;
  background: none;
  border-radius: 50%;
  border: 1px solid gray;
  font-size: 20px;
}

.purchaseDetails .form .input input {
  width: 25%;
  border: none;
  background: none;
  font-size: 20px;
}

.purchaseDetails .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.purchaseDetails .details .circle {
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropbtn svg {
  fill: black;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: -150px;
}

.dropdown-content.two {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: -100px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn svg {
  fill: #270b82;
}

.welcomeCard {
  max-width: 424px;
  max-height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 40px;
  background-color: #ffa000;
  border-radius: 10px;
  position: relative;
  top: -90px;
  left: -30px;
}

.welcomeCard .info {
  padding: 11px 13px;
}

.welcomeCard .info h1 {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
}

.welcomeCard .info p {
  font-weight: 400;
  color: #ffffff;
  font-size: 13px;
  margin: 0;
}

.welcomeCard .image {
  width: 40%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.welcomeCard .image img {
  width: 100%;
  max-width: 100px;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.progress {
  width: 100px;
  height: 100px;
  font-size: 24px;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #ffffff;
  text-align: center;
  line-height: 200px;
  margin: 20px;
}

.progress::after {
  content: "%";
}

.progress .title {
  position: relative;
  z-index: 100;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #07070c;
}

.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
}

.progress .left,
.progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 10px solid #ee9b00;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  -webkit-transform-origin: right;
          transform-origin: right;
}

.progress .left {
  -webkit-animation: load1 1s linear forwards;
          animation: load1 1s linear forwards;
}

.progress:nth-of-type(2) .right,
.progress:nth-of-type(3) .right {
  -webkit-animation: load2 0.5s linear forwards 1s;
          animation: load2 0.5s linear forwards 1s;
}

.progress:last-of-type .right,
.progress:first-of-type .right {
  -webkit-animation: load3 0.8s linear forwards 1s;
          animation: load3 0.8s linear forwards 1s;
}

@-webkit-keyframes load1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@keyframes load1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@-webkit-keyframes load2 {
  0% {
    z-index: 100;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    z-index: 100;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    z-index: 100;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}

@-webkit-keyframes load3 {
  0% {
    z-index: 100;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    z-index: 100;
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
  }
}

@keyframes load3 {
  0% {
    z-index: 100;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    z-index: 100;
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
  }
}

.sectionOne {
  height: 80vh;
  width: 40%;
  padding: 0;
}

.sectionOne .purchaseCard {
  width: 100%;
  background: #0f0162;
  background: linear-gradient(257deg, #0f0162 34%, #1085f6 100%);
  padding: 20px;
  margin: 20px;
  height: 50%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 20px;
}

.sectionOne .purchaseCard .purchaseCardTitle {
  width: 80%;
  padding: 5px;
  background-color: #fec626;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  border: 1px solid black;
}

.sectionOne .purchaseCard .content {
  position: relative;
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sectionOne .purchaseCard .content .ant-progress-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 110;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
}

.sectionOne .purchaseCard .content .ant-progress-circle .ant-progress-inner .ant-progress-text {
  color: black !important;
  z-index: 999 !important;
  position: absolute;
  top: 60px;
}

.sectionOne .purchaseCard .content .ant-progress-circle .ant-progress-circle-trail {
  stroke: #ffffff00 !important;
}

.sectionOne .purchaseCard .content .progressBg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-39%, -39%);
          transform: translate(-39%, -39%);
  z-index: 100;
}

.sectionOne .purchaseCard .content .progressFg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-39%, -39%);
          transform: translate(-39%, -39%);
  z-index: 109;
}

.sectionOne .purchaseCard .content .progressFg svg {
  position: relative;
}

.sectionOne .purchaseCard .content .progressLine {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 2%;
}

.sectionOne .purchaseCard .content .progressLine .ant-progress .ant-progress-outer {
  background: #f5f5f5;
  border-radius: 30px;
  padding: 0px 5px;
  position: relative;
  top: 30px;
}

.sectionOne .purchaseCard .content .progressLine .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  background: -webkit-gradient(linear, left top, right top, from(#0308ee), to(#fc2727)) !important;
  background: linear-gradient(90deg, #0308ee 0%, #fc2727 100%) !important;
}

.sectionOne .purchaseCard .content .progressLine .ant-progress .ant-progress-text {
  color: white !important;
  font-size: 2rem;
  position: relative;
  top: -30px;
}

.sectionOne .purchaseCard .content .progressLine .ant-btn-group {
  width: 25% !important;
  position: relative !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
  -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
          justify-content: space-evenly !important;
  margin-top: 3%;
}

.sectionOne .purchaseCard .content .progressLine .ant-btn-group button {
  width: 45px;
  height: 45px;
  border-radius: 15px;
  /* padding   : 4px; */
  background: -webkit-gradient(linear, left top, right top, from(#ee3a03), to(#ffb700));
  background: linear-gradient(90deg, #ee3a03 0%, #ffb700 100%);
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
}

.sectionOne .purchaseCard .content .progressLine .ant-btn-group button .anticon {
  background: white;
  margin: auto;
  width: 80%;
  height: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
}

.sectionOne .purchaseCard .content .progressLine .ant-btn-group button .anticon svg {
  font-size: x-large;
}

.sectionOne .purchaseCard button.purchase {
  background-image: -webkit-gradient(linear, left top, right top, from(#fec626), color-stop(51%, #ff3c00), to(#fec626));
  background-image: linear-gradient(to right, #fec626 0%, #ff3c00 51%, #fec626 100%);
  width: 50%;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 20px;
  height: 35px;
}

.sectionOne .purchaseCard button.purchase:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.sectionOne .purchaseCard button.history {
  width: 70%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-image: -webkit-gradient(linear, left top, right top, from(#fec626), color-stop(51%, #ff3c00), to(#fec626));
  background-image: linear-gradient(to right, #fec626 0%, #ff3c00 51%, #fec626 100%);
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 20px;
  display: block;
  height: 35px;
}

.sectionOne .purchaseCard button.history:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad {
  background-image: -webkit-gradient(linear, left top, right top, from(#403b4a), color-stop(51%, #e7e9bb), to(#403b4a));
  background-image: linear-gradient(to right, #403b4a 0%, #e7e9bb 51%, #403b4a 100%);
}

.btn-grad {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  -webkit-box-shadow: 0 0 20px #eee;
          box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.ProfileContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  font-family: "Poppins", sans-serif;
}

.ProfileContainer .cardOne {
  width: 50%;
  height: 100%;
  border-radius: 20px;
  background-color: #e0e0e0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.ProfileContainer .cardOne .image {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.ProfileContainer .cardOne .image img {
  width: 100%;
  max-height: 350px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.ProfileContainer .cardOne .info {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 30px 10px;
  position: relative;
  top: -30px;
  background-color: #e0e0e0;
}

.ProfileContainer .cardOne .info .title {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.ProfileContainer .cardOne .info .title .data {
  font-size: 16px;
  font-weight: 400;
  color: #a4a4a4;
}

.ProfileContainer .cardTwo {
  width: 50%;
  height: 100%;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.ProfileContainer .cardTwo .subCardOne {
  height: 50%;
  padding: 30px;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin: 30px 0px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.ProfileContainer .cardTwo .subCardOne .header {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
}

.ProfileContainer .cardTwo .subCardOne .data {
  font-size: 16px;
  font-weight: 400;
  color: #a4a4a4;
}

.ProfileContainer .cardTwo .subCardTwo {
  height: 50%;
  padding: 30px;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin: 30px 0px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.schedulePreview {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  padding: 10px;
  width: 150px;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 20px;
}

.schedulePreview p.date {
  margin: 0;
  font-weight: 500;
}

.schedulePreview p.time {
  margin: 0;
  font-weight: 500;
}

.schedulePreview.warning {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  padding: 10px;
  width: 200px;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 20px;
  background-color: #DC3545;
}

.schedulePreview.warning p.date {
  margin: 0;
  font-weight: 500;
  color: #ffffff;
}

.schedulePreview.warning p.time {
  margin: 0;
  font-weight: 500;
  color: #ffffff;
}

.ant-picker-dropdown {
  z-index: 1056 !important;
}

.appointment {
  margin: 10px !important;
  min-width: 120px !important;
}

.appointment-content {
  overflow: scroll !important;
}

.appointment-picker {
  display: unset !important;
}

.appointment-picker__col {
  -webkit-box-orient: unset !important;
  -webkit-box-direction: unset !important;
      -ms-flex-direction: unset !important;
          flex-direction: unset !important;
}

.appointment-picker__col__name {
  font-size: 18px !important;
  color: #000000 !important;
  width: 60px !important;
  margin: 0px 50px !important;
}
