@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

$primary: #160199;
$white: #ffffff;
$red: red;

.formWrapper {
    width: 100% !important;
    margin: auto;
    border-radius: 20px;
    .formHeader {
        padding: 10px 30px;
        width: 100%;

        font-family: "Poppins", sans-serif;
        background-color: $primary;
        color: $white;

        .title {
            font-weight: 400;
            font-size: 24px;
            margin-bottom: 0;
        }
    }

    form {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;

        div {
            max-height: 400px !important;
            overflow-y: scroll !important;
            div {
               
                input {
                    border-radius: 15px;
                    outline: none !important;
                }
                input:active,
                input:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }
                label:focus,
                label:active {
                    outline: none !important;
                }
                label.mandatory::after {
                    content: "\002A";
                    color: $red;
                }

                select {
                    border-radius: 15px;
                }
            }
            
        }
        div::-webkit-scrollbar {
            display: none;
          }
        .btn {
            border: none;
            outline: none;
            padding: 10px 25px;
            background-color: $primary;
            color: $white;
        }
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .formWrapper {
        width: 100% !important;
        margin: auto;
        .formHeader {
            font-family: "Poppins", sans-serif;
            text-align: justify;
            .title {
                text-align: justify;
            }
        }
        form {
            width: 100%;
            max-width: none !important;
            padding: 10px;
        }
    }
}
