@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.formWrapper {
  width: 100% !important;
  margin: auto;
  border-radius: 20px;
}

.formWrapper .formHeader {
  padding: 10px 30px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #160199;
  color: #ffffff;
}

.formWrapper .formHeader .title {
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 0;
}

.formWrapper form {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.formWrapper form div {
  max-height: 400px !important;
  overflow-y: scroll !important;
}

.formWrapper form div div input {
  border-radius: 15px;
  outline: none !important;
}

.formWrapper form div div input:active,
.formWrapper form div div input:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.formWrapper form div div label:focus,
.formWrapper form div div label:active {
  outline: none !important;
}

.formWrapper form div div label.mandatory::after {
  content: "\002A";
  color: red;
}

.formWrapper form div div select {
  border-radius: 15px;
}

.formWrapper form div::-webkit-scrollbar {
  display: none;
}

.formWrapper form .btn {
  border: none;
  outline: none;
  padding: 10px 25px;
  background-color: #160199;
  color: #ffffff;
}

@media (min-width: 320px) and (max-width: 768px) {
  .formWrapper {
    width: 100% !important;
    margin: auto;
  }
  .formWrapper .formHeader {
    font-family: "Poppins", sans-serif;
    text-align: justify;
  }
  .formWrapper .formHeader .title {
    text-align: justify;
  }
  .formWrapper form {
    width: 100%;
    max-width: none !important;
    padding: 10px;
  }
}
